$(document).ready(function () {
    initDropdown();
    initAccord();
    initKeyBind();
    initDateScroller();
})

function initAccord() {
    $(document).on('click', '.togglebtn', function() {
      const el = $(this).parent().closest('.app-accord');
      $(this).children('i').toggleClass('deg180');
      el.children('.accord-body').animate({
        height: 'toggle'
      }, 0, () => {
        $('.app-boxes').masonry({
          itemSelector: '.grid-box'
        });
        el.children('.accord-body').animate({
          height: 'toggle'
        }, 0, () => {
          el.children('.accord-body').animate({
            height: 'toggle'
          }, 300, () => {});
        });
      });
    });
  }

  function initDropdown() {
      $(document).on('click', '.dropdown-trigger', function() {
          $(this).parent().toggleClass('is-active').siblings('dropdown').removeClass('is-active');

      })

      $(document).on('click', '.dropdown-menu', function() {
        $(this).parent().removeClass('is-active');
      })

      $(document).click(function(e) {
          el = $(e.target);
        if (el.parents('.dropdown').length || el.hasClass('dropdown')) {
        } else {
            $('.dropdown').removeClass('is-active');
        }
      })
  }
  
  function initModal() {
    $(document).on('click', '.modal-background', function() {
      $(this).closest('.modal').removeClass('is-active');
    });

    $(document).on('click', '.close', function() {
      $(this).closest('.modal').removeClass('is-active');
    });

    $(document).on('click', '.modal-trigger', function() {
      const id = $(this).attr('data-toggle');
      if (id) {
        $('#'+id).addClass('is-active');
      }

      initDateScroller();
    })
  }

  function initKeyBind() {
    $(document).keyup(function(e) {
        if (e.keyCode == 27) {
           $('.dropdown').removeClass('is-active');
           $('.modal').removeClass('is-active');
       }
   });
  }

  function initDateScroller() {
    getDatePickerValue();
    $('.date-scroller').scroll(function() {
      times = $(this).children().children('li');
      for (let index = 0; index < times.length; index++) {
        const element = times[index];
        if ($(element).position($(this)).top === 30) {
          classList = $(element).attr('class').split('-');
          const input = $(this).parent().siblings().closest('.time-input');
          value = input.attr('value');
          if (classList[0] === 'hour') {
            newval = value.split(':')
            newval[0] = classList[1];
            $(input).attr('value', newval.join(':'))
          }
          if (classList[0] === 'minute') {
            newval = value.split(':')
            newval[1] = classList[1];
            $(input).attr('value', newval.join(':'))
          }
          if (classList[0] === 'ap') {
            newval = value.split(':')
            newval[2] = classList[1];
            $(input).attr('value', newval.join(':'))
          }
        }
      }
    })
  }

  function getDatePickerValue() {
    times = $('.date-scroller').children().children('li');
    if (times) {
      for (let index = 0; index < times.length; index++) {
        const element = times[index];
        if ($(element).position($(this)).top === 30) {
          classList = $(element).attr('class').split('-');
          const input = $('.date-scroller').parent().siblings().closest('.time-input');
          value = input.attr('value');
          newval = value.split(':')
          if (classList[0] === 'hour') {
            newval[0] = classList[1];
            $(input).attr('value', newval.join(':'))
          }
          if (classList[0] === 'minute') {
            newval[1] = classList[1];
            $(input).attr('value', newval.join(':'))
          }
          if (classList[0] === 'ap') {
            newval[2] = classList[1];
            $(input).attr('value', newval.join(':'))
          }
        }
      }
    }
  }